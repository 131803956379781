import React from 'react'
import toonflix from "../images/toonflix.jpg"
import {Link} from "react-router-dom"
const Navbar = () => {
  return (
<div className="w-full h-20 bg-zinc-900   flex justify-center items-center shadow-2xl   mb-8">
    <nav>
        <div className='items-center  text-center'>
          <Link to="/">
          <img  className="h-[50px] w-[200px] hover:scale-110" src={toonflix} alt="" />
          </Link>
           
        </div>
    </nav>
</div>

  )
}

export default Navbar