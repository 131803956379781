import React from 'react';

const SubHeader = () => {

  return (
    <div className="container mx-auto max-w-[1140px] px-4 py-12">
    <div className=" px-6 bg-white  active md:active lg:active hover:bg-orange-600 rounded-xl  font-bold shadow-lg shadow-zinc-700 hover:text-white active:bg-bg-black  p-2">
      Similar Videos
    </div>
  </div>
  );
};

export default SubHeader;
